import React from "react";

import ScrollToBottom from "react-scroll-to-bottom";

import Message from "./Message/Message";

import "./Messages.css";
import { getLocalStorageInfo } from "../../utils/getInfo";

const Messages = ({
  messages,
  userLanguage,
  room,
  scrollBottomRef,
  adminName,
  setTextDeleteModal,
  setTextDeleteUuid,
}) => {
  const adminInfo = getLocalStorageInfo("adminInfo");
  return (
    <ScrollToBottom className="messages">
      {messages.map((message, i) => {
        return (
          <div key={i}>
            <Message
              setTextDeleteModal={setTextDeleteModal}
              userLanguage={userLanguage}
              message={message}
              room={room}
              adminName={adminInfo?.adminName}
              setTextDeleteUuid={setTextDeleteUuid}
            />
          </div>
        );
      })}
    </ScrollToBottom>
  );
};

export default Messages;
